import type { IconComponent } from './icon';
import { iconService } from './icon.service';
export * from './icon';
export * from './icon.service';

iconService.importStickersheet();

declare global {
  interface HTMLElementTagNameMap {
    'c11n-web-b5-icon': IconComponent;
  }
}
