import styles from './youtube-player.scss?inline';
import slotStyles from './youtube-player.slot.scss?inline';
import { LitElement, html, unsafeCSS, CSSResult, isServer } from 'lit';
import { property, query } from 'lit/decorators.js';
import { literal } from 'lit/static-html.js';
import { C11nPrefix, C11nPrefixLiteral } from 'virtual:c11n-prefix';
import { appendStylesheet, c11nElement, makeDeferred, deferred } from '../core';


@c11nElement()
export class YouTubePlayerComponent extends LitElement {
  static selector = `${C11nPrefix}-youtube-player`;
  static literal = literal`${C11nPrefixLiteral}-youtube-player`;
  static override styles: CSSResult = unsafeCSS(styles);
  static youTubeAPIReady: deferred = makeDeferred();
  static isYouTubeAPIReady: boolean = false;
  
  player: any;
  playerVars = { playsinline: 1, rel: 0 };
  playerOverrides = {};
  youtubeAPIReadyPromise = YouTubePlayerComponent.youTubeAPIReady.promise; 
  playerReadyDeferred = makeDeferred();
  playerReadyPromise = this.playerReadyDeferred.promise;
  
  onAutoplayBlocked = (evt: Event) => {};
  onError = (evt: Event) => {};
  onReady = (evt: Event) => this.player.playVideo();
  onStateChange = (evt: Event) => {};

  @property({ attribute: 'c11n-web-youtube-player', type: Boolean, reflect: true })
  readonly _c11n_web_youtube: boolean = true;

  @property({ attribute: 'show-video', type: Boolean, reflect: true })
  showVideo: boolean = false;

  @property({ attribute: 'video-ready', type: Boolean, reflect: true })
  videoReady: boolean = false;

  @property({ attribute: 'reset-when-done', type: Boolean })
  resetWhenDone: boolean = false;

  @property({ attribute: 'video', type: String })
  _video!: string;

  @query('.yt-wrapper')
  ytEl!: HTMLIFrameElement | HTMLDivElement;

  override connectedCallback(): void {
    super.connectedCallback();
    this.appendStyles();
  }

  appendStyles() {
    const slotStylesheet: string = slotStyles.toString().replaceAll(/\bc11n-web-youtube-player\b/g, this.tagName.toLowerCase());
    appendStylesheet({
      target: this,
      styles: slotStylesheet,
      name: `${this.tagName.toLowerCase()}-slot`
    });
  }

  async displayVideo(evt: Event) {
    this.showVideo = true;
    if (this.player) {
      this.videoReady = true;
      this.player.playVideo(0);
      return;
    }
    this.addYouTubeAPI();
    await YouTubePlayerComponent.youTubeAPIReady.promise;
    this.genPlayer();
  }

  addYouTubeAPI() {
    if (!isServer && !YouTubePlayerComponent.isYouTubeAPIReady) {
      const ytScript = document.createElement('script');
      ytScript.src = 'https://www.youtube.com/iframe_api';
      const headTag = document.querySelector('head') as HTMLHeadElement;
      headTag.appendChild(ytScript);

      /* @ts-expect-error */
      window.onYouTubeIframeAPIReady = () => {
        YouTubePlayerComponent.youTubeAPIReady.resolve();
        YouTubePlayerComponent.isYouTubeAPIReady = true;
      }
    }
  }

  genPlayer() {
    /* @ts-expect-error */
    this.player = new window.YT.Player(this.ytEl, {
      height: '100%', 
      width: '100%', 
      videoId: this._video,
      playerVars: this.playerVars,
      events: {
        onReady: (evt: Event) => {
          this.videoReady = true;
          this.onReady(evt);
        },
        onStateChange: (evt: Event) => {
          /* @ts-expect-error */
          if (this.resetWhenDone && evt?.data === 0) {
            this.videoReady = false;
            this.showVideo = false;
          }
          this.onStateChange(evt);
        },
        onError: (evt: Event) => this.onError(evt),
        onAutoplayBlocked: (evt: Event) => this.onAutoplayBlocked(evt)
      },
      ...this.playerOverrides
    });
    this.playerReadyDeferred.resolve();
  }

  override render() {
    return html`
      <div part="poster" @click="${this.displayVideo}">
        <slot></slot>
      </div>
      <div class="yt-wrapper"></div>
    `;
  }
}

export const C11nYouTubePlayer = YouTubePlayerComponent.selector;
export const C11nYouTubePlayerLiteral = YouTubePlayerComponent.literal;
